import Routing from './Routes/routes';
import './App.css';

function App() {
  return (
<>
<Routing/>
</>
  );
}

export default App;
